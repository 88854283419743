.timeline {
  display: flex;
  position: relative;
  z-index: 3;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  width: 100%;
}

.locked {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.locked > .rbc-event {
  pointer-events: none;
}

/*http://intljusticemission.github.io/react-big-calendar/examples/index.html#prop-toolbar*/

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}
button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled].rbc-btn {
  cursor: not-allowed;
}
button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
}
.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}
.rbc-abs-full,
.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rbc-rtl {
  direction: rtl;
}
.rbc-off-range {
  color: #999999;
}
.rbc-off-range-bg {
  background: #e5e5e5;
}
.rbc-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
}
.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}
.rbc-today {
}
.day + .today {
  color: red !important;
}
.rbc-toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
.rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
  margin-left: 10px;
}

.rbc-event-wrapper {
  color: black;
  border-radius: 2px;
  position: absolute;
  border: rgba(51, 51, 51, 0.5) 1px solid;
}
.rbc-event-label {
  font-size: 80%;
}
.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}
.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-day-after {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-event-continues-day-prior {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-row {
  display: flex;
  flex-direction: row;
}
.rbc-row-segment {
  padding: 0 1px 1px 1px;
}
.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}
.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  white-space: nowrap;
}
.rbc-month-view {
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}
.rbc-month-view .rbc-header {
  border-bottom: 1px solid #ddd;
}
.rbc-month-view .rbc-header + .rbc-header {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-month-view .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-month-header {
  display: flex;
  flex-direction: row;
}
.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  overflow: hidden;
  height: 100%;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #ddd;
}
.rbc-date-cell {
  padding-right: 5px;
  text-align: right;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}
.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.rbc-overlay > * + * {
  margin-top: 1px;
}
.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}
.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
}
.rbc-agenda-view table {
  width: 100%;
  border: 1px solid #ddd;
}
.rbc-agenda-view table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.rbc-agenda-view table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
.rbc-agenda-view table tbody > tr > td + td {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-agenda-view table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-agenda-view table tbody > tr + tr {
  border-top: 1px solid #ddd;
}
.rbc-agenda-view table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.rbc-rtl .rbc-agenda-view table thead > tr > th {
  text-align: right;
}
.rbc-agenda-time-cell {
  text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
  content: ' \BB';
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: '\AB   ';
}
.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}
.rbc-agenda-event-cell {
  width: 100%;
}
.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.timeline.daily-view .rbc-day-slot:nth-child(2) .rbc-timeslot-group:hover {
  flex: 1;
  background-color: white;
  border: 2px solid #2182f6;
  color: #ffffff;
}
.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}
.rbc-timeslot-group {
  border-bottom: 1px solid #ddd;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}
.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}
.rbc-label {
  padding: 0 5px;
}
.rbc-day-slot {
  position: relative;
}

.timeline.daily-view .rbc-day-slot:nth-child(2) {
  cursor: pointer;
}
.timeline.daily-view .rbc-day-slot:nth-child(3) {
  box-shadow: inset 10px 5px 5px #e7e4e4;
}
.timeline.daily-view .rbc-day-slot:nth-child(4) {
  box-shadow: inset 0px 5px 5px #e7e4e4;
}
.timeline.daily-view .rbc-day-slot:nth-child(3),
.timeline.daily-view .rbc-day-slot:nth-child(4) {
  width: 50% !important;
  background-color: #ddddd44d;
}

.rbc-event-label {
  display: none;
}
.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1 0;
  /*word-wrap: break-word;*/
  /*word-break: break-all;*/
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
.rbc-day-slot.rbc-slot-selecting .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
  cursor: grabbing;
}
.rbc-time-slot {
  flex: 1 0 0;
}

.rbc-time-slot.rbc-now {
  font-weight: bold;
}
.rbc-day-header {
  text-align: center;
}

.timeline.daily-view .rbc-day-slot:nth-child(2) .rbc-slot-selection,
.timeline.weekly-view .rbc-day-slot .rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: white;
  color: #467fd7;
  font-weight: 500;
  font-size: 75%;
  padding: 3px;
  width: 100%;
  cursor: grabbing;
  border: 2px solid #2182f6;
}

.timeline.daily-view .rbc-day-slot:nth-child(3) .rbc-slot-selection,
.timeline.daily-view .rbc-day-slot:nth-child(4) .rbc-slot-selection {
  display: none;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid #ddd;
  min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}
.rbc-time-view .rbc-allday-cell {
  width: 100%;
  position: relative;
}
.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.rbc-time-view .rbc-row {
  min-height: 20px;
}
.rbc-time-header {
  display: none;
  /*display: flex;*/
  flex: 0 0 auto;
  flex-direction: column;
}
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #ddd;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #ddd;
}
.rbc-time-header > .rbc-row > * + * {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-time-header > .rbc-row > * + * {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #ddd;
}
.rbc-time-header .rbc-gutter-cell {
  flex: none;
}
.rbc-time-header > .rbc-gutter-cell + * {
  width: 100%;
}
.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  position: relative;
  overflow-x: hidden;
}
.rbc-time-content > .rbc-time-gutter {
  width: 66px;
  flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}
.rbc-current-time-indicator {
  position: absolute;
  z-index: 1;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #e53935;
  pointer-events: none;
}
.rbc-current-time-indicator::before {
  display: block;
  position: absolute;
  left: -3px;
  top: -3px;
  content: ' ';
  background-color: #e53935;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}
.rbc-rtl .rbc-current-time-indicator::before {
  left: 0;
  right: -3px;
}

/*Drag and Drop*/
.rbc-addons-dnd .rbc-row-content {
  pointer-events: none;
}
.rbc-addons-dnd .rbc-row-content .rbc-show-more,
.rbc-addons-dnd .rbc-row-content .rbc-event {
  pointer-events: all;
}
.rbc-addons-dnd .rbc-addons-dnd-over {
  background-color: rgba(0, 0, 0, 0.3);
}

.rbc-addons-dnd .rbc-event {
  transition: opacity 150ms;
}
.rbc-addons-dnd .rbc-event:hover .rbc-addons-dnd-resize-ns-icon,
.rbc-addons-dnd .rbc-event:hover .rbc-addons-dnd-resize-ew-icon {
  display: block;
}
.rbc-addons-dnd.rbc-addons-dnd-is-dragging .rbc-event {
  pointer-events: none;
  opacity: 0.5;
}
.rbc-addons-dnd .rbc-addons-dnd-resizable {
  position: relative;
  width: 100%;
  height: 100%;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor {
  width: 100%;
  text-align: center;
  position: absolute;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:first-child {
  top: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:last-child {
  bottom: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor .rbc-addons-dnd-resize-ns-icon {
  display: none;
  border-top: 3px double;
  margin: 0 auto;
  width: 10px;
  cursor: ns-resize;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor {
  position: absolute;
  top: 4px;
  bottom: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:first-child {
  left: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:last-child {
  right: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor .rbc-addons-dnd-resize-ew-icon {
  display: none;
  border-left: 3px double;
  margin-top: auto;
  margin-bottom: auto;
  height: 10px;
  cursor: ew-resize;
}

.rbc-day-slot .rbc-event {
  border: 1px solid #d3d3d3;
  display: -ms-flexbox;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}

/* new */

.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  /*margin-right: 10px;*/
  top: 0;
}

.rbc-event {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  color: #415059;
  cursor: pointer;
  width: 100%;
  text-align: left;
  transition: all 0.1s ease-in;
}
.rbc-event:hover:not(.email-event) {
  opacity: 0.7;
}
.rbc-event.rbc-event-replicon,
.rbc-event.rbc-event-stacks,
.rbc-event.rbc-event-glimpse {
  background-color: #eaf7e8;
}
.rbc-event.rbc-event-other {
  background-color: #ffebbb;
  color: #415059;
}
.rbc-event.rbc-event-internal {
  background-color: #cfe3f9;
  color: #415059;
}
.rbc-event.rbc-event-pto {
  background-color: #faf8df;
  color: #415059;
}
.rbc-event.rbc-event-selected {
  background-color: #e5e5e5;
  color: #ffffff;
}
.rbc-event-other.rbc-event-selected {
  background-color: #f7f6f6;
  color: #ffffff;
}
.rbc-event.has-unassigned-project {
  background-color: #f6bebc;
}
.rbc-event.rbc-event-offline {
  opacity: 0.7;
  color: #415059;
  background: repeating-linear-gradient(45deg, #eef7ed, #eef7ed 15px, #dbf0d7 15px, #dbf0d7 30px);
}
.rbc-event.rbc-event-timesheet,
.rbc-event.rbc-event-offline.rbc-event-internal {
  background: repeating-linear-gradient(45deg, #bcddf0, #bcddf0 15px, #e3f1f8 15px, #e3f1f8 30px);
}
.rbc-event.rbc-event-offline.is-placeholder,
.rbc-event.rbc-event-timesheet.is-placeholder,
.rbc-event.rbc-event-timesheet.rbc-event-selected.is-placeholder {
  background: white !important;
}

.rbc-event.rbc-event-timesheet.is-placeholder.selected-project,
.rbc-event.rbc-event-timesheet.is-placeholder.selected-project,
.rbc-event.rbc-event-timesheet.is-placeholder.selected-project {
  background-color: #eaf7e8;
}

.rbc-event.rbc-event-offline.rbc-event-other.is-placeholder.selected-project,
.rbc-event.rbc-event-timesheet.rbc-event-other.is-placeholder.selected-project,
.rbc-event.rbc-event-timesheet.rbc-event-selected.rbc-event-other.is-placeholder.selected-project {
  background-color: #ffebbb;
  color: #415059;
}

.rbc-event.rbc-event-offline.rbc-event-internal.is-placeholder.selected-project,
.rbc-event.rbc-event-timesheet.rbc-event-internal.is-placeholder.selected-project,
.rbc-event.rbc-event-timesheet.rbc-event-selected.rbc-event-internal.is-placeholder.selected-project {
  background-color: #cfe3f9;
  color: #415059;
}
.rbc-event.rbc-event-offline.rbc-event-pto.is-placeholder.selected-project,
.rbc-event.rbc-event-timesheet.rbc-event-pto.is-placeholder.selected-project,
.rbc-event.rbc-event-timesheet.rbc-event-selected.rbc-event-pto.is-placeholder.selected-project {
  background-color: #faf8df;
  color: #415059;
}

.rbc-event.rbc-event-offline.has-unassigned-project.is-placeholder.selected-project,
.rbc-event.rbc-event-timesheet.has-unassigned-project.is-placeholder.selected-project,
.rbc-event.rbc-event-timesheet.rbc-event-selected.has-unassigned-project.is-placeholder.selected-project {
  background-color: #f6bebc;
  color: #415059;
}

.rbc-event.rbc-event-offline.has-unassigned-project {
  background: repeating-linear-gradient(45deg, #f6bebc, #f6bebc 15px, #fce4e3 15px, #fce4e3 30px);
}
.rbc-event.rbc-event-offline.is-deleted-entry {
  background: repeating-linear-gradient(45deg, #cccccc, #cccccc 15px, #eeeeee 15px, #eeeeee 30px);
}

.rbc-time-content .rbc-time-column:nth-child(4) .rbc-event-other.email-event,
.rbc-time-content
  .rbc-time-column:nth-child(2)
  .rbc-addons-dnd-drag-preview.rbc-event-other.email-event {
  border: none;
  background-color: transparent;
  color: #0072c6;
  padding: 0;
}

.rbc-time-content .rbc-time-column:nth-child(4) .rbc-event-other .email-envelope-icon,
.rbc-time-content
  .rbc-time-column:nth-child(2)
  .rbc-addons-dnd-drag-preview.rbc-event-other.email-event
  .email-envelope-icon {
  font-size: 19px;
}

.rbc-time-content .rbc-time-column:nth-child(4) .rbc-addons-dnd-resize-ns-anchor {
  display: none;
}

.rbc-time-content .rbc-time-column:nth-child(4) .email-tooltip {
  background-color: white;
  color: black;
}

.rbc-addons-dnd-drag-preview svg:not(:first-child),
.rbc-addons-dnd-drag-preview .email-icon-truncated,
.rbc-time-content
  .rbc-time-column:nth-child(3)
  .rbc-addons-dnd-drag-preview.rbc-event-other.email-event {
  display: none !important;
}

.email-icon-truncated {
  display: inline-block;
  position: relative;
}

.email-envelope-icon.used {
  opacity: 0.5;
}

.email-envelope-icon:hover {
  cursor: pointer;
}

.email-icon-truncated:hover {
  cursor: pointer;
}

.email-event:hover {
  cursor: default;
}

.email-tooltip-date {
  text-align: right;
}

.email-tooltip-subject {
  color: #2196f3;
  font-size: 11px;
}

.email-quantity {
  color: #2196f3;
  position: absolute;
  top: 5px;
  left: 4px;
  font-size: 9px;
  font-weight: bold;
  width: 20px;
  height: 11px;
}

.rbc-day-slot:nth-child(2) .selected-slot-start {
  border-top: 2px solid orange;
  border-left: 2px solid orange;
  border-right: 2px solid orange;
  z-index: 1;
}

.rbc-day-slot:nth-child(2) .selected-slot-middle {
  border-left: 2px solid orange;
  border-right: 2px solid orange;
  z-index: 1;
}

.rbc-day-slot:nth-child(2) .selected-slot-end {
  border-bottom: 2px solid orange;
  border-left: 2px solid orange;
  border-right: 2px solid orange;
  z-index: 1;
}

@media only screen and (max-width: 576px) {
  .daily-view.with-calendar .rbc-time-content div.rbc-day-slot:nth-child(4) {
    display: none;
  }

  .daily-view.with-emails .rbc-time-content div.rbc-day-slot:nth-child(3) {
    display: none;
  }
}
