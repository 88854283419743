/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Arial Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Arial Regular'), url('fonts/ARIAL.woff') format('woff');
}

@font-face {
  font-family: 'Arial Regular';
  font-style: italic;
  font-weight: normal;
  src: local('Arial Italic'), url('fonts/ARIALI.woff') format('woff');
}

@font-face {
  font-family: 'Arial Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Arial Medium'), url('fonts/ArialMdm.woff') format('woff');
}

@font-face {
  font-family: 'Arial Medium';
  font-style: italic;
  font-weight: normal;
  src: local('Arial Medium Italic'), url('fonts/ArialMdmItl.woff') format('woff');
}

@font-face {
  font-family: 'Arial Regular';
  font-style: normal;
  font-weight: bold;
  src: local('Arial Bold'), url('fonts/ARIALBD.woff') format('woff');
}

@font-face {
  font-family: 'Arial Regular';
  font-style: italic;
  font-weight: bold;
  src: local('Arial Bold Italic'), url('fonts/ARIALBI.woff') format('woff');
}

@font-face {
  font-family: 'Arial Regular';
  font-style: normal;
  font-weight: 100;
  src: local('Arial Light Regular'), url('fonts/ARIALLGT.woff') format('woff');
}

@font-face {
  font-family: 'Arial Regular';
  font-style: italic;
  font-weight: 100;
  src: local('Arial Light Italic'), url('fonts/ARIALLGTITL.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Arial Regular', 'Arial Medium', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
