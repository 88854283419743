/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

.DayPicker-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  border-spacing: 0;
  /*border-collapse: collapse;*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;
  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8b9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-Tooltip {
  padding: 0.5em 0.7em;
}

.DayPicker-Tooltip.opened {
  background-color: #f2aaa7;
}

.DayPicker-Day--future .DayPicker-Tooltip.opened,
.DayPicker-Day--today .DayPicker-Tooltip.opened {
  background-color: transparent;
}

.DayPicker-Day:hover:not(.DayPicker-Day--outside) .DayPicker-Tooltip {
  /*outline: 1px solid #51A0FA;*/
  background-color: #f0f8ff;
}

.DayPicker-Tooltip.submitted {
  background-color: #b4d3bb;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4a90e2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #4a90e2;
  color: #f0f8ff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

/*.DayPicker:not(.DayPicker--interactionDisabled)*/
/*.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {*/
/*  background-color: #F0F8FF;*/
/*}*/

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.DayPicker-wrapper {
  padding-bottom: 0;
}

.DayPicker-wrapper:focus {
  outline: none;
}

.DayPicker-Day:focus {
  outline: none;
}

.DayPicker-Day--today {
  color: #000000;
  font-weight: 500;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #ffffff;
  color: #000000;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #ffffff;
}

.DayPicker-Day--selected .DayPicker-Tooltip {
  border: 1px solid #51a0fa;
  padding: calc(0.5em - 1px) calc(0.7em - 1px);
}

.DayPicker-Day--selectedRange .DayPicker-Tooltip {
  border-top: 1px solid #51a0fa !important;
  border-bottom: 1px solid #51a0fa !important;
  padding: calc(0.5em - 1px) 0.7em;
}

.DayPicker-Day--selectedRangeStart .DayPicker-Tooltip {
  border-left: 1px solid #51a0fa;
  padding-left: calc(0.7em - 1px);
}

.DayPicker-Day--selectedRangeEnd .DayPicker-Tooltip {
  border-right: 1px solid #51a0fa;
  padding-right: calc(0.7em - 1px);
}

.DayPicker-Day--selectedRange.DayPicker-Day--outside {
  border-top: 1px solid #51a0fa !important;
  border-bottom: 1px solid #51a0fa !important;
}

.DayPicker-Day--selectedRangeStart.DayPicker-Day--outside {
  border-left: 1px solid #51a0fa;
}

.DayPicker-Day--selectedRangeEnd.DayPicker-Day--outside {
  border-right: 1px solid #51a0fa;
}

.DayPicker-Custom-Caption {
  font-size: 1.1rem;
}

.DayPicker-Custom-NavButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
  margin: 1em;
}
