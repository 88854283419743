/* slide right animation */
.slide-right-enter,
.slide-right-exit {
  position: absolute;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  transition-property: transform, opacity;
}

.slide-right-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-right-enter.slide-right-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.slide-right-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.slide-right-exit.slide-right-exit-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

/* slide left animation */
.slide-left-enter,
.slide-left-exit {
  position: absolute;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  transition-property: transform, opacity;
}

.slide-left-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.slide-left-enter.slide-left-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.slide-left-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.slide-left-exit.slide-left-exit-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

/* slide up animation */
.slide-up-enter,
.slide-up-exit {
  position: absolute;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  transition-property: transform, opacity;
}

.slide-up-enter {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

.slide-up-enter.slide-up-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.slide-up-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.slide-up-exit.slide-up-exit-active {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

/* fade animation */
.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

/* initial mount animation */
.mounting-appear {
  opacity: 0.01;
}

.mounting-appear.mounting-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

/* events animation */
.events-enter {
  transition: opacity 300ms ease-in;
  animation: flyin 0.3s;
}

.events-exit {
  transition: opacity 300ms ease-in;
  animation: flyout 0.3s;
}

/* slide-down animation */
.slide-down-enter {
  transition: opacity 380ms ease-in;
  animation: flyin-down 0.38s;
}

.slide-down-exit {
  transition: opacity 380ms ease-in;
  animation: flyout-down 0.38s;
}

/* arrow animation */
.arrow {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 15px solid #415059;
  border-bottom: 15px solid transparent;
  transition: transform 0.3s ease;
}

.arrow-right {
  transform: rotate(45deg);
}

.arrow-bottom {
  transform: rotate(135deg);
}

@keyframes flyin {
  0% {
    opacity: 0.01;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes flyout {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0.01;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}

@keyframes flyin-down {
  0% {
    opacity: 0.01;
    -webkit-transform: translateY(+100%);
    transform: translateY(+100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes flyout-down {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0.01;
    -webkit-transform: translateY(+100%);
    transform: translateY(+100%);
  }
}
